<template>
  <div class="padding20">
    <iframe
      v-show="isIframeLoaded"
      ref="previewPage"
      class="result-wrapper"
      frameborder="0"
      src="/preview.html"
      @load="iframeLoad"
    />
    <div v-show="!isIframeLoaded" v-loading="true" class="result-wrapper"></div>
  </div>
</template>

<script>
import {
  exportDefault
} from '@/components/fortress-draggable/utils/index'
import {
  makeUpHtml
} from '@/components/fortress-draggable/generator/html'
import { makeUpJs } from '@/components/fortress-draggable/generator/js'
import { makeUpCss } from '@/components/fortress-draggable/generator/css'
import {
  formconfigInfo
} from "@/api/form/formconfig.js"
import { parse } from '@babel/parser'

const editorObj = {
  html: null,
  js: null,
  css: null
}
export default {
  data() {
    return {
      viewFlag: false,
      isIframeLoaded: false,
      isInitcode: false, // 保证open后两个异步只执行一次runcode
      isRefreshCode: false, // 每次打开都需要重新刷新代码
      htmlCode: '',
      jsCode: '',
      cssCode: '',
      formData: {},
      generateConf: { type: 'file' },
      formConfigData: {
        id: "",
        designContent: "",
        tbContent: "",
        viewContent: ""
      }
    }
  },
  created() {
    this.formConfigData.id = this.$route.query.id + '';
    if (this.formConfigData.id) {
      this.initInfo(this.formConfigData.id)
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    initInfo(id) {
      formconfigInfo(id).then((resp) => {
        if (resp.code == 0) {
          if (resp.data.viewContent) {
            this.viewFlag = true;
            this.formData = JSON.parse(resp.data.viewContent);
            this.onOpen();
          } else {
            this.drawingList = [];
          }
        }
      });
    },
    onOpen() {
      const { type } = this.generateConf
      this.htmlCode = makeUpHtml(this.formData, type)
      this.jsCode = makeUpJs(this.formData, type, this.formConfigData.id)
      this.cssCode = makeUpCss(this.formData)
      this.setEditorValue('editorHtml', 'html', this.htmlCode)
      this.setEditorValue('editorJs', 'js', this.jsCode)
      this.setEditorValue('editorCss', 'css', this.cssCode)
      if (!this.isInitcode) {
        this.isRefreshCode = true
        this.isIframeLoaded && (this.isInitcode = true) && this.runCode()
      }
    },
    setEditorValue(id, type, codeStr) {
      editorObj[type] = codeStr
    },
    runCode() {
      const jsCodeStr = editorObj.js
      try {
        const ast = parse(jsCodeStr, { sourceType: 'module' })
        const astBody = ast.program.body
        if (astBody.length > 1) {
          this.$confirm(
            'js格式不能识别，仅支持修改export default的对象内容',
            '提示',
            {
              type: 'warning'
            }
          )
          return
        }
        if (astBody[0].type === 'ExportDefaultDeclaration') {
          const postData = {
            type: 'refreshFrame',
            data: {
              generateConf: this.generateConf,
              html: this.htmlCode,
              js: jsCodeStr.replace(exportDefault, ''),
              css: this.cssCode,
              scripts: [],
              links: []
            }
          }
          this.$refs.previewPage.contentWindow.postMessage(
            postData,
            location.origin
          )
        } else {
          this.$message.error('请使用export default')
        }
      } catch (err) {
        this.$message.error(`js错误：${err}`)
        console.error(err)
      }
    },
    iframeLoad() {
      if (!this.isInitcode) {
        this.isIframeLoaded = true
        this.isRefreshCode && (this.isInitcode = true) && this.runCode()
      }
    },
  }
}
</script>

<style lang='scss' scoped>
@import "@/components/fortress-draggable/styles/index";
@import "@/components/fortress-draggable/styles/home";

.result-wrapper {
  height: calc(100vh - 33px);
  width: 100%;
  overflow: auto;
  padding: 12px;
  box-sizing: border-box;
}
</style>
